/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import React from "react";
import { graphql, Link } from "gatsby";
import { Layout, Seo, Tag, DateTime } from "../components/";

const IndexPage = ({ data }) => {
  const posts = data.allMdx.edges;

  const getYear = (post) => {
    let date = post.node.frontmatter.date;
    let year = date.substring(0, 4);

    return year;
  };

  const postYears = posts.reduce((acc, post) => {
    acc.push(getYear(post));
    return acc;
  }, []);

  const years = [...new Set(postYears)];

  const postsByYear = posts.reduce((acc, post) => {
    let year = getYear(post);

    acc[year] = acc[year] ? [...acc[year], post] : [post];
    return acc;
  }, {});

  return (
    <Layout>
      <Seo title="Blog" keywords={[`gatsby`, `react`, `accessibility`]} />
      <div sx={{ marginBottom: `4rem`, textAlign: `center`, width: `100%` }}>
        <Styled.h1>Blog</Styled.h1>
        <Styled.p sx={{ fontSize: [3, 3, 4] }}>Posts and reflections</Styled.p>
      </div>
      {years.map((year) => (
        <React.Fragment>
          <Styled.h2>{year}</Styled.h2>
          <ul
            sx={{
              listStyleType: `none`,
              padding: 0,
              width: `100%`,
            }}
          >
            {postsByYear[year].map(({ node }) => {
              const { fields, frontmatter } = node;
              const { title, tags, date } = frontmatter;

              return (
                <li
                  sx={{
                    backgroundColor: `#fff`,
                    border: "1px solid rgba(52, 61, 68, 0.05)",
                    borderLeft: "4px solid rgba(52, 61, 68, 0.05)",
                    padding: `.4rem 1rem`,
                    "&:hover": {
                      backgroundColor: "highlight",
                      borderLeft: (theme) =>
                        `4px solid ${theme.colors.secondary}`,
                    },
                  }}
                >
                  <Link
                    to={fields.slug}
                    sx={{
                      flex: `1 0 50%;`,
                      textDecoration: `none`,
                    }}
                  >
                    <div
                      css={{
                        display: `flex`,
                        justifyContent: `space-between`,
                        "@media (max-width: 800px)": {
                          flexDirection: `column`,
                          // height: `unset`,
                          // margin: `0 auto 2rem`,
                        },
                      }}
                    >
                      <Styled.p sx={{ margin: `.2rem` }}>
                        <DateTime
                          dateTime={date}
                          date={date.split(`-`).slice(1, 3).join(`/`)}
                        />{" "}
                        {title}
                      </Styled.p>
                      <Styled.p sx={{ margin: `.2rem` }}>
                        {tags && tags.map((tag) => <Tag tag={tag} />)}
                      </Styled.p>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </React.Fragment>
      ))}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { contentType: { eq: "post" } }
        frontmatter: { hide: { ne: true } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
            tags
          }
        }
      }
    }
  }
`;
